.paystack-button {
    cursor: pointer;
    text-align: center;
    font-size: 10px;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    background-color: blue;
    font-weight: bold;
    color: #e0eafc;
    border: none;
    border-radius: 5px;
    width: 100%;
    height: 45px;
    margin-top: 40px;
  }